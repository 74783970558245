import { LoanOverviewNeedsListFiltersState } from "src/components/loans/tabs/overview/v2/loan-overview-needs-list/loan-overview-needs-list.types";

export const isAnyPackageFilterOn = (filters: LoanOverviewNeedsListFiltersState, persistKey: 'PACKAGE' | 'NEEDS_LIST'): boolean => {
    return !!filters.modified ||
        filters.type.length > 0 ||
        filters.status.length > 0 ||
        filters.assigneesIds.length > 0 ||
        filters.phaseCategory !== null ||
        filters.entitiesIds.length > 0 ||
        filters.unAssigned ||
        filters.groupBy !== null && persistKey !== 'NEEDS_LIST';
}