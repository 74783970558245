import { useState } from "react";
import { LoanPhaseCategoryType, LoanReviewStatus } from "src/backend";
import { useLazyGetLoanReviewStatusElementsQuery } from "src/services/packageApi";
import { setLoanReviewStatus } from "src/slices/loan";
import { useDispatch } from "src/store";
import { Loan } from "src/types/loan";
import { getReviewStatusLabel } from "src/utils/loan/get-review-status-label";

interface LoanOverviewReadyForReviewProps {
    loan: Loan;
}

export const LoanReviewStatusPhaseCategoryMap: Record<LoanReviewStatus, LoanPhaseCategoryType[]> = {
    ['ARCHIVE']: ['ARCHIVE'],
    ['CLOSING']: ['ORIGINATION'],
    ['HOLD']: ['LEAD', 'ORIGINATION'],
    ['IN_DEFAULT']: ['PORTFOLIO'],
    ['IN_PROGRESS']: ['ORIGINATION'],
    ['IN_SERVICE']: ['PORTFOLIO'],
    ['LEAD']: ['LEAD'],
    ['READY_FOR_REVIEW_PENDING']: ['ORIGINATION'],
    ['READY_FOR_REVIEW_REVIEW']: ['ORIGINATION'],
    ['READY_FOR_UNDERWRITING']: ['ORIGINATION'],
    ['REVIEW_COMPLETE_ACCEPTED']: ['ORIGINATION'],
    ['REVIEW_COMPLETE_CONDITIONAL']: ['ORIGINATION'],
    ['REVIEW_COMPLETE_REJECTED']: ['ORIGINATION'],
    ['WITHDRAWN']: ['LEAD', 'ORIGINATION'],
};

const StatusItems: {
    status: LoanReviewStatus;
    label: string;
    chipLabel: string | null;
    phaseCategories: LoanPhaseCategoryType[];
}[] = [
        {
            status: 'IN_PROGRESS',
            label: getReviewStatusLabel('IN_PROGRESS'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['IN_PROGRESS']
        },
        {
            status: 'ARCHIVE',
            label: getReviewStatusLabel('ARCHIVE'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['ARCHIVE']
        },
        {
            status: 'IN_SERVICE',
            label: getReviewStatusLabel('IN_SERVICE'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['IN_SERVICE']
        },
        {
            status: 'IN_DEFAULT',
            label: getReviewStatusLabel('IN_DEFAULT'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['IN_DEFAULT']
        },
        {
            status: 'LEAD',
            label: getReviewStatusLabel('LEAD'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['LEAD']
        },
        {
            status: 'READY_FOR_UNDERWRITING',
            label: getReviewStatusLabel('READY_FOR_UNDERWRITING'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['READY_FOR_UNDERWRITING']
        }, {
            status: 'READY_FOR_REVIEW_PENDING',
            label: getReviewStatusLabel('READY_FOR_REVIEW_PENDING'),
            chipLabel: "Pending",
            phaseCategories: LoanReviewStatusPhaseCategoryMap['READY_FOR_REVIEW_PENDING']
        }, {
            status: 'READY_FOR_REVIEW_REVIEW',
            label: getReviewStatusLabel('READY_FOR_REVIEW_REVIEW'),
            chipLabel: "In Review",
            phaseCategories: LoanReviewStatusPhaseCategoryMap['READY_FOR_REVIEW_REVIEW']
        }, {
            status: 'REVIEW_COMPLETE_ACCEPTED',
            label: getReviewStatusLabel('REVIEW_COMPLETE_ACCEPTED'),
            chipLabel: "Accepted",
            phaseCategories: LoanReviewStatusPhaseCategoryMap['REVIEW_COMPLETE_ACCEPTED']
        }, {
            status: 'REVIEW_COMPLETE_CONDITIONAL',
            label: getReviewStatusLabel('REVIEW_COMPLETE_CONDITIONAL'),
            chipLabel: "Conditional",
            phaseCategories: LoanReviewStatusPhaseCategoryMap['REVIEW_COMPLETE_CONDITIONAL']
        }, {
            status: 'REVIEW_COMPLETE_REJECTED',
            label: getReviewStatusLabel('REVIEW_COMPLETE_REJECTED'),
            chipLabel: "Rejected",
            phaseCategories: LoanReviewStatusPhaseCategoryMap['REVIEW_COMPLETE_REJECTED']
        },
        {
            status: 'CLOSING',
            label: getReviewStatusLabel('CLOSING'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['CLOSING']
        }, {
            status: 'HOLD',
            label: getReviewStatusLabel('HOLD'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['HOLD']
        }, {
            status: 'WITHDRAWN',
            label: getReviewStatusLabel('WITHDRAWN'),
            chipLabel: null,
            phaseCategories: LoanReviewStatusPhaseCategoryMap['WITHDRAWN']
        }];

export const useLoanOverviewReadyForReview = (props: LoanOverviewReadyForReviewProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState<LoanReviewStatus>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<LoanReviewStatus>(null);
    const [getReviewStatusElements, { data: reviewStatusData }] = useLazyGetLoanReviewStatusElementsQuery();

    const dispatch = useDispatch();

    const handleChange = async (reviewStatus: LoanReviewStatus) => {
        // first we check if there is elements related to this phase
        const { data: elementsData } = await getReviewStatusElements({ id: props.loan.id, loanReviewStatus: reviewStatus });

        if (elementsData?.list.length > 0) {
            setIsDialogOpen(reviewStatus);
        } else {
            // show normal confirmation
            setConfirmationDialog(reviewStatus);
        }
    }

    const handleConfirm = () => {
        dispatch(setLoanReviewStatus(props.loan.id, confirmationDialog ?? isDialogOpen));
        setConfirmationDialog(null);
        setIsDialogOpen(null);
        setIsDropdownOpen(false);
    }

    const onCancel = () => {
        setConfirmationDialog(null);
        setIsDialogOpen(null);
        setIsDropdownOpen(false);
    }

    const selectedItem = StatusItems.find(item => item.status === props.loan.reviewStatus);

    const filteredItems = StatusItems.filter(item => item.phaseCategories.includes(props.loan.loanPhase.category));

    return {
        reviewStatus: props.loan.reviewStatus,
        confirmationDialog,
        isDropdownOpen,
        onDropdownOpenChange: setIsDropdownOpen,
        onChange: handleChange,
        onConfirm: handleConfirm,
        onDialogChangeOpen: setIsDialogOpen,
        isDialogOpen,
        loan: props.loan,
        fileRequestsIds: reviewStatusData?.list.filter(element => element.storageType === 'FILE').map(item => item.id) ?? [],
        selectedItem,
        items: filteredItems,
        totalElements: reviewStatusData?.totalFileElements ?? 0,
        elements: reviewStatusData?.tree ?? [],
        onCancel
    } as const
}
